<template>
  <div class="home">
  <div class="title">Revotec Administration</div>
  <div class="subtitle">Hier ist Platz für eine Projektbeschreibung oder eine Weiterleitung auf die Hauptseite.</div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
