<template>
<div class="component">
    <div class="logincontainer columns is-mobile is-multiline">

        <div class="column is-6 is-offset-3">
            <input v-model="email" id=""   v-on:change="removeFormError('email') " :class="{'inputerror':checkFormfieldError('email')}" class="input" placeholder="E-Mail" />
        </div>
        <div class="column is-6 is-offset-3" v-on:change="removeFormError('password') ">
            <input v-model="password" class="input" type="password" :class="{'inputerror':checkFormfieldError('password')}" placeholder="Passwort" />
        </div>
        <div class="column is-4 is-offset-4">
            <div class="button is-success" @click="login()">Login</div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'Login',
    props: [],
    mounted: function () {},
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            formfielderrors: [],
            email: "",
            password: ""
        }
    },
    methods: {
        checkFormfieldError(field) {
            return this.formfielderrors.indexOf(field) > -1
        },
        removeFormError(field){

            this.formfielderrors = this.formfielderrors.filter(e => e !== field);
          
        },
        login(){
            if(this.email==""||this.email==null){
                this.formfielderrors.push('email')
            }

            if(this.password==""||this.password==null){
                this.formfielderrors.push('password')
            }

            if(this.formfielderrors.length>0){
                console.log("errors in fields")
                return;
            }


             let postData = {
                   'password': this.password,
                    'email': this.email
                }
                this.axios.post(this.$backendURL + "auth/login", postData).then((response) => {
                    console.log(response.data)
                    
                localStorage.setItem('bearertoken', response.data.access_token);
                            this.$router.push({ name: 'Home' })

                })


        }
    },
    computed: {},
}
</script>

<style scoped>
.inputerror {
    border: 1px solid #d00;
}
.inputerror:hover {
    border: 1px solid #d00;
}
</style>
