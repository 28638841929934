<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/devices">Devices</router-link>
    </div>
    <router-view/>
  </div>
</template>



<script>
//import qfheader from '@/components/header.vue'


export default {
    name: 'App',
    props: [],
    components: {
   
  },
    mounted: function () {


        this.$router.beforeEach((to, from, next) => {
            if (to.name == "Login" || to.name == "login" || to.name == "Register" ) {
                next();
            } else {
                if (localStorage.getItem('bearertoken') == null) {
                    next({
                        path: '/login',
                    })
                } else {

                    this.axios.get(this.$backendURL + "user", {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.bearertoken
                        }
                    }).then((response) => {
                        console.log(response.data)
                        next();

                    }).catch((error) => {
                        next({
                            path: '/login',
                        })
                    })

                }
            }

        })
    },
    data() {
        return {
            data: [],
            infoMessageData:""
        }
    },
    methods: {},
    computed: {},
}
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
