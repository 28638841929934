<template>
<div class="component">
    <div class="devicelist">

        <div class="columns is-mobile">
            <div class="column is-3 is-offset-9 ">
                <div class="button is-success" @click="openCreateNewDevice=true">Neues Gerät</div>
            </div>
        </div>
        <div class="columns is-mobile is-multiline" v-if="openCreateNewDevice">
            <div class="column is-4 is-offset-2"><input v-model="newDeviceMAC" class="input" placeholder="MAC" /></div>
            <div class="column is-6">
                <div class="button is-success" @click="createNewDevice()">Speichern</div>
            </div>
        </div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-6 title">Identifizierungsnummer ( IDE )</div>
            <div class="column is-6 title">MAC-Addresse</div>
        </div>
        <div class="columns is-mobile is-multiline" v-for="device in devices" v-if="devices.length>0">
            <div class="column is-6">{{device.IDE}}</div>
            <div class="column is-6">{{device.MAC}}</div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'component',
    props: [],
    mounted: function () {
        this.getAllDevices();
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            devices: [],
            openCreateNewDevice: false,
            newDeviceIDE: "",
            newDeviceMAC: ""
        }
    },
    methods: {
        getAllDevices() {
            let postData = {
                'password': this.password,
                'email': this.email
            }

            this.axios.post(this.$backendURL + "admin/device/all", postData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            }).then((response) => {

                this.devices = response.data.devices;
            })

        },
        createNewDevice() {
            if (this.newDeviceMAC == "") {
                return;
            }
            let postData = {
               
                'MAC': this.newDeviceMAC
            }
            this.axios.post(this.$backendURL + "admin/device/add", postData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            }).then((response) => {
                this.newDeviceMAC = "";
                this.newDeviceIDE = "";
                this.openCreateNewDevice = false;
                this.getAllDevices();
            })

        }
    },
    computed: {},
}
</script>

<style scoped>

</style>
